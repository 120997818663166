import { useEffect, useRef } from "react";
import Dropzone from "dropzone";
import {
  deleteBatchButton,
  dnd,
  dropzoneButtons,
  dropzonePadding,
  dropzonePreview,
  fileCancel,
  fileInputButton,
  fileRow,
  fileUploadPreview,
  fileUploadPreviewName,
  fileUploadPreviewSize,
  fileUploadPreviewText,
} from "../Styles";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Icon,
  PrimaryButton,
} from "@fluentui/react";
import { useState } from "react";
import { generateBlobFileUri } from "./BatchFilesPivot";
import { byteArrayToBase64, dwordstobytes } from "utils/base64";
import { bytesToWords, wordsToMd5 } from "utils/md5-functions";
import worker from "utils/worker";
import WebWorker from "utils/workerSetup";
import { useBoolean } from "@fluentui/react-hooks";

Dropzone.autoDiscover = false;

const dialogContentProps = {
  type: DialogType.normal,
  title: "Confirm Send",
  closeButtonAriaLabel: "Close",
  subText: "Do not leave to go to another tab while the files are loading!",
};

const DropZoneFile = (props) => {
  const { sas, fetchBatch } = props;
  const inputRef = useRef(null);
  const [dropzone, setDropzone] = useState(null);
  const [showDropzone, setShowDropzone] = useState(sas ? true : false);
  const [isSending, setIsSending] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const setFileProgressText = (file, text) => {
    if (file.previewElement) {
      var preview = file.previewElement;
      preview.querySelector("#progress-status").textContent = text;
    }
  };

  const uploadDropzoneFiles = (dropzone) => {
    var files = dropzone.getAddedFiles();
    files.forEach((file) => {
      if (!file.uploaded) {
        setFileProgressText(file, "Preparing Upload");
        dropzone.enqueueFile(file);
      }
    });
    setIsSending(true);
    setShowDropzone(false);
  };

  const cancelUploadDropzoneFiles = (dropzone) => {
    var uploadingFiles = dropzone.getUploadingFiles();
    uploadingFiles.forEach((file) => {
      file.cancelUpload = true;
      dropzone.cancelUpload(file);
    });
    var allFiles = uploadingFiles.concat(
      dropzone.getAddedFiles(),
      dropzone.getQueuedFiles()
    );
    allFiles.forEach((file) => {
      dropzone.removeFile(file);
    });
  };

  useEffect(() => {
    var previewNode = document.querySelector("#template");
    previewNode.id = "";
    var previewTemplate = previewNode.parentNode.innerHTML;
    previewNode.parentNode.removeChild(previewNode);

    var blockQueue = [];
    var blockSending = 0;
    var blockMaxSending = 10;

    var md5OffloadSize = 128 * 1024;
    var md5Workers = [];

    var w;
    var workers = Math.max(navigator.hardwareConcurrency - 2, 1);
    for (w = 0; w < workers; w++) {
      var md5Worker = new WebWorker(worker);
      md5Worker.onmessage = function (event) {
        var data = event.data[0];
        var hash = event.data[1];

        if (this.file.cancelUpload) {
          return;
        }

        var b64 = byteArrayToBase64(dwordstobytes(hash));
        this.xhr.setRequestHeader("Content-MD5", b64);

        crypto.subtle.digest("SHA-512", data).then((shaHash) => {
          b64 = byteArrayToBase64(new Uint8Array(shaHash));

          if (!this.chunk) {
            this.xhr.setRequestHeader("x-ms-meta-sha512", b64);
          } else {
            // save for seperate .SHA512 file
            this.chunk.sha512 = b64;
          }

          this.xhr._send.call(this.xhr, data);
          this.xhr = null;
        });
      };
      md5Workers.push(md5Worker);
    }

    const dropzoneProps = {
      url: "/",
      paramName: "file", // The name that will be used to transfer the file
      createImageThumbnails: true,
      thumbnailWidth: 80,
      thumbnailHeight: 80,
      parallelUploads: 10,
      autoProcessQueue: true,
      autoQueue: false,
      previewsContainer: "#previews", // Define the container to display the previews
      clickable: "#clickableArea", // Define the element that should be used as click trigger to select files.
      timeout: 300000,
      chunking: true,
      chunkSize: 16777216, // 16MB (100MB is max supported chunk size by Azure Storage)
      maxFilesize: 800000, // MB... 50,000 chunks. 0.76TB with 16MB chunks
      retryChunks: true,
      parallelChunkUploads: true,
      previewTemplate: previewTemplate,

      init: function () {},

      accept: function (file, done) {
        // When we drop individual files, the name is file.name and fullPath is undefined
        // When we drop folders, fullPath is filled in, and name is the immediate name
        if (!file.fullPath) {
          file.fullPath = file.name;
        }

        // sadly there isn't a callback to choose the display name.  Override here to use full name
        var elem = file.previewElement
          .querySelectorAll("[data-dz-name]")
          [Symbol.iterator]();
        for (; (elem = elem.next()); !elem.done) {
          elem.value.textContent = file.fullPath;
          break;
        }
        done();
      },

      params: function (files, xhr, chunk) {
        // Dropzone supports multiple blobs in a single put, we have that disabled, so there will just be 1 here
        var file = files[0];
        file.cancelUpload = false;
        var sasUrl = generateBlobFileUri(sas, file.name);

        // This is used to upload the blob pieces
        file.sasUrl = sasUrl;

        // For chunk, add blockid to URL
        if (chunk) {
          var blockid = "" + chunk.index;
          while (blockid.length < 6) {
            blockid = "0" + blockid;
          }
          blockid = btoa(blockid);
          sasUrl = sasUrl + "&comp=block&blockid=" + blockid;
        }

        // reopen request to replace the URL.  Then set headers
        xhr.open("PUT", sasUrl);
        xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
        xhr.setRequestHeader("x-ms-version", "2019-07-07");
        xhr.setRequestHeader("Content-Type", file.type);

        // override send() to read file, calc md5, send
        var _send = xhr.send;

        if (chunk) {
          xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
              blockSending--;
              while (blockSending < blockMaxSending) {
                if (blockQueue.length === 0) {
                  break;
                }

                // pop from queue
                var chunk = blockQueue.shift();

                // this will trigger read, md5, send
                chunk.reader.readAsArrayBuffer(chunk.dataBlock.data);
                blockSending++;
              }
            }
          };
        }

        xhr._send = _send;
        xhr.send = () => {
          // read the content, calc MD5, then send the data
          var reader = new FileReader();
          reader.onload = function () {
            if (file.cancelUpload) {
              return;
            }

            var data = new Uint8Array(reader.result);

            var foundWorker = false;

            if (data.length > md5OffloadSize) {
              // pass to background thread.  We will resume in the md5Worker.onmessage after the calc is done
              var workerOffset = Math.floor(Math.random() * md5Workers.length);
              var worker;

              for (worker = 0; worker < md5Workers.length; worker++) {
                var md5Worker =
                  md5Workers[(worker + workerOffset) % md5Workers.length];
                if (!md5Worker.xhr) {
                  md5Worker.xhr = xhr;
                  md5Worker.file = file;
                  md5Worker.chunk = chunk;
                  md5Worker.postMessage([data], [data.buffer]);
                  foundWorker = true;
                  break;
                }
              }
            }

            if (!foundWorker) {
              // use the main thread
              var hash = wordsToMd5(bytesToWords(data), data.length * 8);
              var b64 = byteArrayToBase64(dwordstobytes(hash));
              xhr.setRequestHeader("Content-MD5", b64);

              if (file.cancelUpload) {
                return;
              }

              crypto.subtle.digest("SHA-512", data).then((shaHash) => {
                b64 = byteArrayToBase64(new Uint8Array(shaHash));

                if (!chunk) {
                  xhr.setRequestHeader("x-ms-meta-sha512", b64);
                } else {
                  chunk.sha512 = b64;
                }
                _send.call(xhr, data);
              });
            }
          };

          if (chunk) {
            if (blockSending > blockMaxSending) {
              // Save this for processing later in queue
              chunk.reader = reader;
              blockQueue.push(chunk);
            } else {
              reader.readAsArrayBuffer(chunk.dataBlock.data);
              blockSending++;
            }
          } else {
            reader.readAsArrayBuffer(file);
          }
        };
      },

      success: function (file, response) {
        if (!file.uploaded) {
          file.uploaded = true;
          setFileProgressText(file, "Uploaded");
        }
      },

      complete: function () {
        if (this.getQueuedFiles().length > 0) {
          this.processQueue();
        } else if (this.getUploadingFiles().length === 0) {
          const allFiles = myDropzone.getAcceptedFiles();
          allFiles.forEach((file) => {
            myDropzone.removeFile(file);
          });
          setShowDropzone(true);
          setIsSending(false);
          fetchBatch();
        }
      },

      chunksUploaded: function (file, done) {
        var _done = done;

        // Make a request to write the block list
        var xhrPBL = new XMLHttpRequest();
        xhrPBL.open("PUT", file.sasUrl + "&comp=blocklist");

        var i;
        var chunks = file.upload.chunks.length;
        var body = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
        for (i = 0; i < chunks; i++) {
          var blockid = "" + i;
          while (blockid.length < 6) {
            blockid = "0" + blockid;
          }
          body += "<Latest>" + btoa(blockid) + "</Latest>";
        }
        body += "</BlockList>";

        xhrPBL.onreadystatechange = function () {
          if (xhrPBL.readyState === XMLHttpRequest.DONE) {
            // also write blob with sha512s
            var sha512s = '<?xml version="1.0" encoding="utf-8"?><blocks>';
            var offset = 0;
            for (i = 0; i < chunks; i++) {
              sha512s +=
                "<block><offset>" +
                offset +
                "</offset><size>" +
                file.upload.chunks[i].total +
                "</size><sha512>" +
                file.upload.chunks[i].sha512 +
                "</sha512></block>";
              offset += file.upload.chunks[i].total;
            }
            sha512s += "</blocks>";

            var xhrPBSha512 = new XMLHttpRequest();
            var splitUrl = file.sasUrl.split("?");
            var sha512Url = splitUrl[0] + ".teleportal.sha512?" + splitUrl[1];
            xhrPBSha512.open("PUT", sha512Url);
            xhrPBSha512.setRequestHeader("x-ms-blob-type", "BlockBlob");
            xhrPBSha512.setRequestHeader("x-ms-version", "2019-07-07");

            xhrPBSha512.onreadystatechange = function () {
              if (xhrPBSha512.readyState === XMLHttpRequest.DONE) {
                _done();
              }
            };

            xhrPBSha512.onerror = function () {
              if (xhrPBSha512.readyState === XMLHttpRequest.DONE) {
                _done("error");
              }
            };
            xhrPBSha512.send(sha512s);
          }
        };

        xhrPBL.onerror = function () {
          if (xhrPBL.readyState === XMLHttpRequest.DONE) {
            _done("error");
          }
        };
        xhrPBL.send(body);
      },

      removedfile: function (file) {
        if (
          file.previewElement != null &&
          file.previewElement.parentNode != null
        ) {
          file.previewElement.parentNode.removeChild(file.previewElement);
        }

        const filesCount =
          this.getAddedFiles().length +
          this.getQueuedFiles().length +
          this.getUploadingFiles().length;
        if (filesCount === 0) {
          setShowProgress(false);
        }
      },
    };

    let myDropzone = new Dropzone("div#dropzoneArea", dropzoneProps);

    myDropzone.on("addedfile", (file) => {
      setShowProgress(true);
    });

    // function to update the UI to show what the percent complete is
    myDropzone.on("uploadprogress", function (file, progress, bytesSent) {
      setFileProgressText(file, progress.toFixed() + "% Complete");
    });

    // Hide the file progress div when everything is done
    myDropzone.on("queuecomplete", function (progress) {
      setShowProgress(false);
    });

    setDropzone(myDropzone);
  }, [sas, fetchBatch]);

  return (
    <div className={dropzonePadding}>
      <div
        ref={inputRef}
        id="clickableArea"
        className={fileInputButton}
        style={{ display: showDropzone ? "" : "none" }}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            inputRef.current.click();
          }
        }}
      >
        <div id="dropzoneArea" className={dnd}>
          <Icon iconName="CloudUpload" />
          <div>
            <strong>Choose files</strong> or drag them here
          </div>
        </div>
      </div>
      <div
        id="actions"
        className="row"
        style={{ display: showProgress ? "" : "none" }}
      >
        <div className={dropzoneButtons}>
          <PrimaryButton
            text="Start Upload"
            onClick={() => toggleHideDialog()}
            disabled={isSending}
          />
          <PrimaryButton
            text="Cancel Upload"
            className={deleteBatchButton}
            onClick={() => cancelUploadDropzoneFiles(dropzone)}
          />
        </div>
        <div
          className={`${dropzonePreview} table table-striped files dropzone-previews`}
          id="previews"
        >
          <div id="template" className={fileRow}>
            <div className="entry">
              <div className="info">
                <div className={fileUploadPreview}>
                  <div className={fileUploadPreviewText}>
                    <div className={fileUploadPreviewName} data-dz-name></div>
                    <div className={fileUploadPreviewSize} data-dz-size></div>
                    <div className={fileCancel} data-dz-remove>
                      <Icon iconName="Cancel" />
                    </div>
                  </div>
                </div>
                <div className="progress-container">
                  <div
                    className="progress progress-striped active"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    aria-valuenow="0"
                  >
                    <div
                      className="progress-bar progress-bar-success"
                      data-dz-uploadprogress
                    ></div>
                  </div>
                </div>
                <div className="footer-container">
                  <div id="progress-status">Ready to Upload</div>
                  <div className="dz-error-message">
                    <span data-dz-errormessage></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              uploadDropzoneFiles(dropzone);
              toggleHideDialog();
            }}
            text="Send"
          />
          <DefaultButton onClick={toggleHideDialog} text="Don't send" />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default DropZoneFile;
